export default {
    "6": "rafis",
    "47": "bondi",
    "48": "baran",
    "50": "press",
    "52": "byggidn",
    "53": "ein",
    "54": "fia",
    "55": "fit",
    "56": "fs",
    "57": "fsare",
    "58": "ki",
    "59": "lis",
    "60": "LL",
    "61": "lsos",
    "62": "matvis",
    "63": "pfi",
    "64": "strv",
    "65": "framsyn",
    "66": "stag",
    "67": "vssi",
    "68": "stfs",
    "69": "svg",
    "70": "verkvest",
    "71": "vfi",
    "72": "vsfk",
    "73": "vr",
    "77": "stkop",
    "89": "slfi",
    "90": "bhm",
    "103": "tfi",
    "110": "ffr",
    "115": "vm",
    "116": "fvfi",
    "128": "stala",
    "139": "vlfs",
    "140": "stis",
    "143": "stolis",
    "144": "kot",
    "145": 'stama',
    "146": 'sfl',
    "148": "sveg",
    "149": "drifandi",
    "150": "verks",
    "151": "fji"
}