import axios from 'axios';
import jQuery from "jquery";

import storage from 'services/storage/storage';
import * as config from 'config';
import {transformResponse} from 'services/transform';

// Set default request parameters
axios.defaults.baseURL = config.baseUrl;
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';

// TODO:  Need to refactor all logic inside this file and make it more organized
class Request {
    request = async (path, method, data, config = {}) => {
        const requestData = prepareRequestData(path, method, data, config);

        let res = null;

        await axios.request(requestData)
            .then(response => {
                res = transformResponse(response);
            })
            .catch(async error => {
                error = transformResponse(error);

                throw error;
            });

        return res;
    };
}

const req = new Request();

export const postRequest = (path, data, config = {}) => {
    return req.request(path, 'post', data, config);
};

export const putRequest = (path, data, config = {}) => {
    return req.request(path, 'put', data, config);
};

export const patchRequest = (path, data, config = {}) => {
    return req.request(path, 'patch', data, config);
};

export const deleteRequest = (path, id) => {
    return req.request(`${path}/${id}`, 'delete');
};

export const getRequest = (path, params, config = {}) => {
    return req.request(path, 'get', params, config);
};


export const processPath = (path) => {
    if (path.indexOf('http') === 0) {
        return path;
    }

    if (path.indexOf('/') !== 0) {
        path = '/member/' + path;
    }

    if (path.indexOf('/') !== 0) {
        path = '/' + path;
    }

    path = '/' + storage.get('interface', {}).id + path;

    return path;
};

const prepareRequestData = (path, method, data, config = {}) => {
    path = processPath(path);

    const requestData = {
        url: path,
        method: method,
        ...config,
    };

    if (method === 'get' || method === 'delete') {
        requestData.params = data;
    }

    if (method === 'post' || method === 'put' || method === 'patch') {
        requestData.data = data;
    }

    return requestData;
};

export const download = (path, lang = null, params = {}) => {
    let url = processPath(path);

    if (lang) {
        params = {...params, lang};
    }

    url = config.baseUrl + url + '?' + jQuery.param(params);

    window.open(url, '_blank');
};