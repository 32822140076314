import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import {Link} from "react-router-dom"
import {Link as ExternalLink} from "@mui/material";
import styles from './styles/styles';
import {useAuth} from "@/providers/Auth/AuthProvider";
import useTranslate from "@/components/UseTranslate/useTranslate";
import PrivacyPolicyModal from "./components/PrivacyPolicyModal/PrivacyPolicyModal";

const Footer = props => {
    const {footerHeight} = props;
    const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);

    const {accountInterface, user, isSpecificUnion} = useAuth();
    const {t} = useTranslate();

    if (_.isEmpty(accountInterface)) {
        return null;
    }

    const {account} = accountInterface;
    const unionLogo = account?.union_logo;
    const privacyPolicyUrl = account?.privacy_policy_url;

    const openPrivacyPolicyModal = () => {
        setShowPrivacyPolicyModal(true);
    }

    const closePrivacyPolicyModal = () => {
        setShowPrivacyPolicyModal(false);
    }

    const formatPhoneNumber = (number) => {
        const str = number.toString();

        const countryCode = '+' + str.slice(0, 3);
        const part1 = str.slice(3, 6);
        const part2 = str.slice(6, 10);

        return `${countryCode} ${part1}-${part2}`;
    }

    const formatURL = (url) => {
        return url.replace(/^https?:\/\//, '').replace(/\/$/, '');
    }

    return (
        <Box className='footer' sx={(theme) => styles.footer(theme, footerHeight)}>
            <Container>
                <Box sx={styles.footerTop}>
                    <List component="nav" sx={styles.footerTopList}>
                        <ListItem sx={styles.logoFooterItem}>
                            <Box
                                component={'img'}
                                src={unionLogo}
                                sx={styles.logo}
                            />
                        </ListItem>
                        <Box sx={styles.footerRightBlock}>
                            {
                                !isSpecificUnion &&
                                <ListItem sx={styles.linkFooterItem}>
                                    <Box>
                                        <Box sx={(theme) => styles.boldTextItem(theme, isSpecificUnion)}>
                                            <Typography variant={'p4Bold'}>{account?.name}</Typography>
                                        </Box>
                                        <Typography variant={'p4'}>{account?.address}</Typography>
                                    </Box>
                                </ListItem>
                            }
                            {
                                !isSpecificUnion &&
                                <ListItem sx={styles.linkFooterItem}>
                                    {
                                        account &&
                                        <Typography
                                            variant={'p4'}>
                                            {(account.phone_code && account.phone_number) && formatPhoneNumber(account.phone_code + account.phone_number)}
                                        </Typography>
                                    }
                                </ListItem>
                            }
                            {
                                !isSpecificUnion &&
                                <ListItem sx={styles.linkFooterItem} className={'email'}
                                          onClick={() => window.location = `mailto:${account?.email}`}>
                                    <Typography variant={'p4'}>{account?.email}</Typography>
                                </ListItem>
                            }
                            {
                                !isSpecificUnion && !!account.url &&
                                <ListItem sx={styles.linkFooterItem}>
                                    <Typography variant={'p4'} className={'account-url'}>
                                        <ExternalLink variant='p3'
                                                      href={account.url}>{formatURL(account.url)}</ExternalLink>
                                    </Typography>
                                </ListItem>
                            }
                        </Box>
                    </List>
                </Box>

                <Box sx={styles.footerBottom}>
                    <List component="nav" sx={styles.footerBottomList}>
                        <ListItem sx={styles.linkFooterItem}>
                            <List sx={styles.footerList}>
                                {
                                    (!isSpecificUnion && privacyPolicyUrl) &&
                                    <ListItem sx={styles.linkFooterItem}>
                                        <Typography variant={'p3'} sx={styles.link}>
                                            <ExternalLink href={privacyPolicyUrl || ''} target='_blank' variant='body2'>
                                                {t('privacy_policy')}
                                            </ExternalLink>
                                        </Typography>
                                    </ListItem>
                                }
                                {
                                    isSpecificUnion &&
                                    <ListItem sx={styles.linkFooterItem}>
                                        <Typography variant={'p3'} sx={styles.link}>
                                            <ExternalLink variant='body2' onClick={openPrivacyPolicyModal}>
                                                {t('privacy_policy')}
                                            </ExternalLink>
                                        </Typography>
                                    </ListItem>
                                }
                                {
                                    user &&
                                    <ListItem sx={styles.linkFooterItem}>
                                        <Typography variant={'p3'} sx={styles.link}>
                                            <Link to='/unm/faq' variant='p3' color='primary'>
                                                {t('faq')}
                                            </Link>
                                        </Typography>
                                    </ListItem>
                                }
                            </List>
                        </ListItem>
                        <ListItem sx={styles.linkFooterItem}>
                            <Box sx={styles.smallText}>
                                <Typography>{t('footer')}</Typography>
                            </Box>
                        </ListItem>
                    </List>
                </Box>

                <PrivacyPolicyModal
                    open={showPrivacyPolicyModal}
                    onClose={closePrivacyPolicyModal}
                />

            </Container>
        </Box>
    )
};

export default Footer;