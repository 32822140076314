import React, {useEffect, useState} from 'react';
import ActiveForm, {ActiveInput, ActiveSelect} from "@/components/ActiveForm/ActiveForm";
import {Form} from "formik";
import notification from "@/services/notification/notification";
import * as Yup from "yup";
import {Dialog} from "@mui/material";
import styles from '../../../styles/styles';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
    createMemberAddress,
    getMemberAddress,
    updateMemberAddress
} from "@/modules/UnionMember/services/api/addresses/addresses";
import {getCountries} from "@/services/api/country/country";
import ActiveAutoComplete from "@/components/ActiveForm/components/ActiveAutoComplete/ActiveAutoComplete";
import useTranslate from "@/components/UseTranslate/useTranslate";
import {addressTypeOptions} from "@/layouts/components/Header/components/ProfileInformation/staticData/staticData";
import {API_STATUSES} from "@/services/api/statuses/statuses";
import DeleteAddressModal
    from "@/layouts/components/Header/components/ProfileInformation/components/Addresses/components/DeleteAddressModal";
import {Resource} from "@/services/transform/resource/resource";
import MemberAddressTransformer
    from "@/modules/UnionMember/services/transform/resource/transformers/Address/MemberAddressTransformer";
import {useLang} from "@/providers/LangProvider/LangProvider";

const OTHER = 2;

const CreateOrUpdateAddressModal = (props) => {
    const {id, usedTypes, open} = props;
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [address, setAddress] = useState(null);
    const {t} = useTranslate();
    const {lang} = useLang();

    useEffect(() => {
        if (id) {
            fetchData()
                .then(resp => setAddress(resp))
                .catch(() => notification.warning(t('something_wrong')))
        }
    }, [id]);

    const modalName = id ? 'update' : 'create';

    const fetchData = async () => {
        const response = await getMemberAddress(id, {include: ['country']});
        return Resource.transform(MemberAddressTransformer, response.data, response.meta).data;
    }

    const fetchCountryData = () => getCountries({per_page: 300}).then(resp => {
        return resp?.data.map((item) => {
            const countryName = _.find(item.translations, {lang}).name;

            return {value: countryName, label: countryName, id: item.id}
        })
    });

    const onCreate = async (data) => {
        return await createMemberAddress({...data, type: data.type.value, country_id: data.country.id});
    }

    const onUpdate = async (data) => {
        return await updateMemberAddress(data.id, {...data, country_id: data.country.id, type: data.type.value});
    }

    const onSuccess = () => {
        notification.success(t(`alert_address_${modalName}_success`));
        props.onSuccess();
        onClose();
    }

    const onClose = () => {
        props.onClose();
        setAddress(null);
    }

    const openDeleteModal = () => {
        if (!address.is_default) {
            setIsOpenDeleteModal(true)
        }
    }

    const onSuccessDelete = () => {
        setIsOpenDeleteModal(false)
        props.onSuccess()
        onClose()
    }

    const addressTypeTranslations = {
        legal_residence: t('legal_residence'),
        residence: t('residence'),
        other: t('other'),
    };

    if (!address && id) {
        return null;
    }

    const translatedAddressOptions = addressTypeOptions.map(option => {
        const isTypeUsed = usedTypes.includes(option.value);
        const shouldInclude = option.value === OTHER || option.value === address?.type;

        if (!shouldInclude && isTypeUsed) {
            return;
        }

        return {...option, label: addressTypeTranslations[option.label]};
    }).filter(Boolean);

    const countryName = address?.country.translations?.find(translation => translation.lang === lang)?.name;

    return (
        <>
            <Dialog
                open={open}
                sx={styles.emailModal}
                className={'modal'}
                onClose={onClose}
            >
                <Box className={'modalHeader'}>
                    <Box className={'modalHeaderInner'}>
                        <Typography variant='h4'>
                            {t(`title_${modalName}_address`)}
                        </Typography>
                    </Box>
                </Box>

                <ActiveForm
                    initialValues={address ? {
                            ...address,
                            country: {
                                ...address.country,
                                label: countryName,
                                value: countryName
                            },
                            type: _.find(translatedAddressOptions, item => item.value === address.type)
                        } :
                        {
                            type: '',
                            city: '',
                            address: '',
                            zip_code: '',
                            is_default: false
                        }}
                    onSubmit={id ? onUpdate : onCreate}
                    onSuccess={onSuccess}
                    validationSchema={Yup.object().shape({
                        type: Yup.object().required('required').typeError("required"),
                        country: Yup.object().typeError('required').required('required'),
                        city: Yup.string().min(2, 'min').max(60, 'max').required('required').typeError("required"),
                        address: Yup.string().min(2, 'min').max(255, 'max').required('required').typeError("required"),
                        zip_code: Yup.string().max(15, 'max').required('required').typeError("required"),
                        is_default: Yup.boolean()
                    })}
                    onError={(createMode, form, values, errors, resp) => {
                        if (resp.status === API_STATUSES.CONFLICT) {
                            notification.error(t(`error_code_${resp.error_code}`))
                        } else {
                            notification.error(t('something_wrong'));
                        }
                    }}
                    render={(formProps) => {
                        const {handleSubmit, isValid, isSubmitting, values} = formProps;

                        return (
                            <Form onSubmit={handleSubmit} className='p-0'>
                                <Box className={'modalBody'}>
                                    <Box mb={3}>
                                        <ActiveSelect
                                            name="type"
                                            TextFieldProps={{
                                                placeholder: t('type')
                                            }}
                                            label={t('type')}
                                            title={t('type')}
                                            options={translatedAddressOptions}
                                            onError={key => t(`error_type_${key}` || '')}
                                            required
                                        />
                                        <Typography variant={'p5'}>{t('text_address')}</Typography>
                                    </Box>
                                    <Box pb={3} sx={styles.addressRow}>
                                        <Box pr={3} width={'100%'} className={'address'}>
                                            <ActiveInput
                                                name="address"
                                                label={`${t('address')}`}
                                                placeholder={t('address')}
                                                title={t('address')}
                                                onError={errKey => t(`error_address_${errKey}`, {
                                                    min: 2,
                                                    max: 255
                                                })}
                                                required
                                            />
                                        </Box>
                                        <Box width={'100%'} className={'zip_code'}>
                                            <ActiveInput
                                                name="zip_code"
                                                label={`${t('zip_code')}`}
                                                placeholder={t('zip_code')}
                                                title={t('zip_code')}
                                                onError={errKey => t(`error_zip_code_${errKey}`)}
                                                required
                                            />
                                        </Box>
                                    </Box>
                                    <Box sx={styles.addressRow}>
                                        <Box pr={3} width={'100%'} className={'city'}>
                                            <ActiveInput
                                                name="city"
                                                label={`${t('placeholder_city')}`}
                                                placeholder={t('placeholder_city')}
                                                title={t('placeholder_city')}
                                                onError={errKey => t(`error_city_${errKey}`, {
                                                    min: 2,
                                                    max: 60
                                                })}
                                                required
                                            />
                                        </Box>
                                        <Box width={'100%'} sx={styles.selectOptionBox}>
                                            <Typography variant={'p4'} sx={styles.countryLabel}>
                                                {t('placeholder_country')}
                                                <Box sx={styles.countryLabelSign}>*</Box>
                                            </Typography>
                                            <ActiveAutoComplete
                                                id="asynchronous-demo"
                                                name={'country'}
                                                defaultValue={address && values.country}
                                                loadOptions={() => fetchCountryData()}
                                                placeholder={t('placeholder_country')}
                                                onError={errKey => t(`error_city_${errKey}`)}
                                            />
                                        </Box>
                                    </Box>

                                    {
                                        !_.isEmpty(address) &&
                                        <Box mt={3}>
                                            <Typography
                                                variant={'p4'}
                                                className={values?.is_default ? 'delete-email-btn-disabled' : 'delete-email-btn'}
                                                onClick={openDeleteModal}
                                            >
                                                {t('remove_address')}
                                            </Typography>
                                        </Box>
                                    }
                                </Box>
                                <Box className={'modalFooter'}>
                                    <Box className={'modalFooterInner'}>
                                        <Button variant='contained' color="secondary" onClick={onClose}>
                                            {t('close')}
                                        </Button>
                                        <Button type={'submit'} onClick={handleSubmit} variant='contained'
                                                disabled={!isValid || isSubmitting}>
                                            {t(`${modalName}`)}
                                        </Button>
                                    </Box>
                                </Box>
                            </Form>
                        )
                    }}
                />
            </Dialog>

            <DeleteAddressModal
                open={isOpenDeleteModal}
                onClose={() => setIsOpenDeleteModal(false)}
                onSuccess={onSuccessDelete}
                data={address}
                countryName={countryName}
            />
        </>
    )
}

export default CreateOrUpdateAddressModal;