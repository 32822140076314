import Transformer from 'services/transform/resource/transformers/Transformer';

class CountryTransformer extends Transformer {
    static transform(country) {
        const {...result} = country;

        return result;
    }
}

export default CountryTransformer;