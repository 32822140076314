import * as React from 'react';
import {forwardRef} from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import {Box, Divider, Typography} from "@mui/material";
import MuiGrow from "@mui/material/Grow";
import LanguageIcon from "@mui/icons-material/Language";
import styles from "./styles/styles";
import CloseIcon from "@/icons/Close";
import useTranslate from "@/components/UseTranslate/useTranslate";
import {languages} from "@/config";
import {withRouter} from "react-router-dom";
import {useLang} from "providers/LangProvider/LangProvider";

//TODO Remove this after removing MATERIAL UI 4
const Grow = forwardRef((props, ref) => {
    return <MuiGrow  {...props} ref={ref} timeout={10}/>
});


const LangSwitcher = (props) => {
    const {t} = useTranslate();
    const {lang: currentLang, changeLang} = useLang();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageChange = (e, newLang) => {
        e.preventDefault();

        changeLang(newLang);
        handleClose();
    };

    return (
        <Box>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                endIcon={<LanguageIcon sx={(theme) => styles.langIcon(theme, props.mode === 'light')}/>}
                sx={(theme) => styles.langButton(theme, props.mode === 'light')}
            >
                {t(`lang_switcher_${currentLang}`)}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
                TransitionComponent={Grow}
            >
                <Box sx={styles.langMenu}>
                    <Box textAlign={'right'} mb={1}>
                        <Box p={1} sx={styles.closeIconBox}>
                            <CloseIcon color={'primary'} onClick={handleClose} sx={styles.closeIcon}/>
                        </Box>
                    </Box>
                    <Box sx={styles.langOptionsBox}>
                        {
                            languages.map((language, index) => (
                                <Box key={index}>
                                    <Box
                                        sx={styles.langOption}
                                        onClick={(event) => handleLanguageChange(event, language)}
                                    >
                                        <Typography
                                            variant={'p4'}
                                            sx={(theme) => styles.langLabel(theme, currentLang === language)}
                                        >
                                            {t(`${language}`)}
                                        </Typography>
                                    </Box>
                                    {index !== languages.length - 1 && <Divider sx={styles.divider}/>}
                                </Box>
                            ))}
                    </Box>
                </Box>
            </Menu>
        </Box>
    );
}

export default withRouter(LangSwitcher);