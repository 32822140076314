import React, {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute'
import Home from "pages/Home/Home";
import Loader from "@/components/Loader/Loader";
import useGlobal from "@/modules/Accommodation/state/state";
import {getPropertyParameters} from "@/modules/Accommodation/services/api/propertyParameter/propertyParameter";
import {Resource} from "@/services/transform/resource/resource";
import {
    PropertyParameterTransformer
} from "@/modules/Accommodation/services/transform/resource/transformers/transformers";
import {useLang} from "providers/LangProvider/LangProvider";
import {useAuth} from "@/providers/Auth/AuthProvider";

const PVC = React.lazy(() => import('@/modules/PVC/PVC'));
const Discount = React.lazy(() => import('@/modules/Discount/Discount'));
const Accommodation = React.lazy(() => import('@/modules/Accommodation/Accommodation'));
const UnionMember = React.lazy(() => import('@/modules/UnionMember/UnionMember'));
const Credit = React.lazy(() => import('@/pages/Credit/Credit'));
const Points = React.lazy(() => import('@/pages/Points/Points'));
const DkPlus = React.lazy(() => import('@/modules/DkPlus/DkPlus'));
const NotFound = React.lazy(() => import('@/pages/NotFound/NotFound'));
const Landing = React.lazy(() => import('@/pages/Landing/Landing'));


const Routes = () => {
    const [state, actions] = useGlobal();
    const {lang} = useLang();
    const {checkAccess} = useAuth();

    useEffect(() => {
        if (state.systemParameters.length) {
            return;
        }

        checkAccess('acm_member_privileges') && getPropertyParameters({
            lang: lang,
            per_page: 300,
            include: ['icon']
        }).then(resp => {
            const data = Resource.transform(PropertyParameterTransformer, resp.data, resp.meta).data;

            const parameters = data.map(param => ({
                id: param.id,
                type: param.type,
                name: param.name,
                icon: param.icon,
            }));

            actions.setSystemParameters(parameters);
        });
    }, []);


    return (
        <React.Suspense fallback={<Loader/>}>
            <Switch>
                <PrivateRoute exact path='/:lang(is|en)?' component={Home}/>
                <PrivateRoute path={"/:lang(is|en)?/:module(pvc)/:page(\w+)?"} component={PVC}/>
                <PrivateRoute path={"/:lang(is|en)?/:module(acm)/:page(\w+)?"} component={Accommodation}/>
                <PrivateRoute path={"/:lang(is|en)?/:module(dk-plus)/:page(\w+)?"} component={DkPlus}/>
                <PrivateRoute path={"/:lang(is|en)?/:module(unm)/:page(\w+)?"} component={UnionMember}/>
                <PrivateRoute path={"/:lang(is|en)?/:page(home)"} component={Home}/>
                <PrivateRoute path={'/:lang(is|en)?/:page(landing)'} component={Landing}/>
                <PrivateRoute path={"/:lang(is|en)?/:page(credit)"} component={Credit}/>
                <PrivateRoute path={"/:lang(is|en)?/:page(points)"} component={Points}/>
                <PrivateRoute path={"/:lang(is|en)?/:module(dsc)/:page(\w+)?"} component={Discount}/>

                {/*Not Found page*/}
                <Route path="*"><NotFound/></Route>
            </Switch>
        </React.Suspense>
    )
};

export default Routes;