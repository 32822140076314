import React, {createContext, useContext, useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import moment from "moment";

import {defaultLang, languages} from 'config';

export const LangContext = createContext({});

const LangProvider = ({match, history, children}) => {
    const routeLang = match.params.lang;
    const initialLang = languages.includes(routeLang) ? routeLang : defaultLang;

    const [lang, setLang] = useState(initialLang);

    useEffect(() => {
        updateDatesLang(lang);
    }, []);

    const changeLang = (newLang) => {
        const {search, pathname} = history.location;
        const langRegexp = new RegExp(`^/(${languages.join("|")})/`, 'i');
        const containsLang = langRegexp.test(pathname);
        const newPathname = containsLang ? pathname.replace(langRegexp, `/${newLang}/`) : `/${newLang}${pathname}`;

        setLang(newLang);
        updateDatesLang(newLang);
        history.push(newPathname + search);
    }

    const updateDatesLang = (lang) => {
        moment.locale(lang);
    }

    return (
        <LangContext.Provider value={{lang, changeLang}}>
            {children}
        </LangContext.Provider>
    )
}

export default withRouter(LangProvider);

export const useLang = () => {
    return useContext(LangContext);
};