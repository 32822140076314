import React from 'react';
import {withRouter} from "react-router-dom";
import storage from "@/services/storage/storage";
import {useAuth} from "@/providers/Auth/AuthProvider";
import {useLang} from "providers/LangProvider/LangProvider";

const Home = (props) => {
    const {landingConfigs} = useAuth();
    const customLandingPageUrl = landingConfigs?.custom_landing_url;
    const url = window.location.href;
    const redirectUrl = storage.get('redirect_url');

    const {lang} = useLang();

    const {checkCustomUrl} = props.location.state || {};

    if (redirectUrl) {
        storage.remove('redirect_url');

        return props.history.push(redirectUrl);
    }

    if (checkCustomUrl && customLandingPageUrl) {
        if (customLandingPageUrl.startsWith(url)) {
            return props.history.push(customLandingPageUrl.substring(url.length))
        } else {
            window.location.replace(customLandingPageUrl)
        }
    }

    if (landingConfigs?.is_active_landing) {
        return props.history.push(`/${lang}/landing`);
    }

    if (!customLandingPageUrl) {
        props.history.push(`/${lang}/not_found`);
        return null;
    }

    if (customLandingPageUrl.startsWith(url)) {
        props.history.push(customLandingPageUrl.substring(url.length))
    } else {
        window.location.replace(customLandingPageUrl)
    }

    return null;
};

export default withRouter(Home)