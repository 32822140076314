import React from 'react';
import styles from "@/layouts/components/Menu/styles/styles";
import {useAuth} from "@/providers/Auth/AuthProvider";
import {useLang} from "providers/LangProvider/LangProvider";

export default () => {
    const {checkAccess} = useAuth();
    const {lang} = useLang();

    return [
        {
            nodeId: '5',
            label: 'faq',
            path: `/${lang}/unm/faq`,
            sx: styles.itemRoot,
            show: checkAccess('unm_member_privileges'),
        },
    ];
}