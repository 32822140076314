import React from 'react';
import LoginForm from './components/LoginForm/LoginForm';
import {withRouter} from "react-router-dom";
import PublicLayout from "@/layouts/Public/Public";
import * as src from './images/loginBackgtound.png';
import * as leigutorgBackgroundSrc from './images/leigutorgBackground.jpg';
import Box from "@mui/material/Box";
import {useAuth} from "@/providers/Auth/AuthProvider";
import storage from "@/services/storage/storage";
import AudkenniForm from "@/pages/Login/components/AudkenniForm/AudkenniForm";

const LoginTypes = {
    DEFAULT: 0,
    AUDKENNI: 1,
}

const Login = (props) => {
    const {accountInterface, user, isSpecificUnion} = useAuth();
    const {login_url: loginUrl, login_type: loginType} = accountInterface;


    if (user) {
        props.history.push('/');
    }

    const query = new URLSearchParams(props.location.search);
    const redirectUrl = query.get('redirect');

    if (redirectUrl) {
        storage.set('redirect_url', redirectUrl);
    }

    return (
        <>
            <PublicLayout
                page={'login'}
                HeaderProps={{
                    hasOffset: false,
                }}
                showFooter={true}
            >
                <Box sx={{
                    backgroundImage: `url(${isSpecificUnion ? leigutorgBackgroundSrc : src})`,
                    width: "100%",
                    height: "100vh",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                }}>
                    <Box>
                        <Box item position={'relative'}>

                            {
                                +loginType === LoginTypes.DEFAULT && <LoginForm {...props}/>
                            }
                            {
                                +loginType === LoginTypes.AUDKENNI && <AudkenniForm/>
                            }
                        </Box>
                    </Box>
                </Box>
            </PublicLayout>
        </>
    )
};


export default withRouter(Login);